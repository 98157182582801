.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.AppSegments {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  width: calc(100vw - 64px);
  max-width: calc(100vw - 64px);
  position: relative;
}

/* Code example */

.code-example__pre,
.code-example__example {
  padding: 20px;
}
.code-example__example {
  border-top-right-radius: 0.3em;
  border-top-left-radius: 0.3em;
  background-color: white;
  border: 1px solid #dedede;
}
.code-example__example:first-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.code-example__example__heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  color: #999;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;
}
.code-example__example__heading--no-rule {
  color: #999;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
}
.code-example__pre {
  background: #fafafa;
  border: 1px solid #dedede;
  border-top: none;
  margin: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.code-example__pre:last-child {
  border-bottom-right-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
}
.code-example__example-element {
  border-radius: 0.3em;
  padding: 0.5em 1.5em;
}
.code-example__example-element--block + .code-example__example-element--block {
  margin-top: 1em;
}
.code-example__example-element--inline
  + .code-example__example-element--inline {
  margin-top: 1em;
}
@media (min-width: 768px) {
  .code-example__example-element--inline {
    display: inline-block;
    vertical-align: text-bottom;
  }
  .code-example__example-element--inline
    + .code-example__example-element--inline {
    margin-left: 1em;
    margin-top: 0;
  }
}
.code-example__example-element--primary-bg {
  background-color: #1385e5;
}

/* Button */

h3 {
  margin-top: 1em;
}

.react-grid-item.react-grid-placeholder {
  background: rgb(180, 179, 179) !important;
}
